<template>
  <div class="container mx-auto flex w-full flex-col gap-4 lg:flex-row">
    <div
      class="sidebared-page"
      data-testid="sidebared-page"
      :class="[
        sidebaredPage({
          pageWidth: width ?? undefined
        })
      ]"
    >
      <slot name="page" />
    </div>

    <Sidebar>
      <slot name="sidebar" />
    </Sidebar>
  </div>
</template>

<script lang="ts" setup>
import type { SidebaredPageProps } from '@autobid/ui/utils/cva/sidebaredPageCva'
import { sidebaredPage } from '@autobid/ui/utils/cva/sidebaredPageCva'
import Sidebar from './Sidebar.vue'

interface Props {
  width?: SidebaredPageProps['pageWidth']
}

defineProps<Props>()
</script>
