<template>
  <ClientOnly>
    <HeadlessTransitionRoot appear :show="opened" as="template">
      <HeadlessDialog
        as="div"
        class="relative z-50 overflow-visible"
        @close="() => (disableDismissOnClickOutside ? null : $emit('close'))"
      >
        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black opacity-70" />
        </HeadlessTransitionChild>
        <div class="fixed inset-0 overflow-visible overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center overflow-visible p-4 text-center"
          >
            <HeadlessTransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <HeadlessDialogPanel
                :class="popupCva({ type, width })"
                v-bind="containerProps"
                class="container flex max-h-[94vh] w-full transform flex-col overflow-visible border-4 bg-white text-left align-middle shadow-xl transition-all"
              >
                <HeadlessDialogTitle
                  v-bind="headerProps"
                  as="header"
                  class="relative flex w-full items-center justify-between px-4 pb-3 pt-4 text-lg font-semibold uppercase tracking-wider text-primary after:absolute after:bottom-0 after:left-4 after:right-4 after:border-b after:border-primary"
                >
                  <slot name="title" />

                  <button
                    class="flex text-2xl duration-300 hover:scale-110 hover:text-primary"
                    @click="$emit('close')"
                  >
                    <Icon name="ic:outline-close" />
                  </button>
                </HeadlessDialogTitle>

                <main
                  v-bind="mainProps"
                  class="flex-1 overflow-y-auto px-4 py-3 text-sm text-gray-500"
                >
                  <slot name="content" />
                </main>

                <footer class="w-full px-4 pb-4 pt-3" v-bind="footerProps">
                  <slot name="buttons" />
                </footer>
              </HeadlessDialogPanel>
            </HeadlessTransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { popupCva } from '@autobid/ui/utils/cva/popupCva'
import type { DialogProps } from '@autobid/ui/types/components/Dialog'

defineEmits(['close'])
defineProps<DialogProps>()
</script>
